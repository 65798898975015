'use client';

import Loading from '@/app/(dynamic)/loading';
import {
  fetchCurrentNewWeek,
  fetchNewWeeks,
  fetchTextAndTools,
  fromServerTime,
  isError,
} from '@/lib/helpers';
import {
  ErrorResponse,
  isLectionaryType,
  LECTIONARY_TOOLS_TAB,
  LECTIONARY_TYPES_MAP,
  LECTIONARY_TYPES_TAB,
  LectionaryWeeks,
  NewLectionaryWeeks,
  NewLectionaryWeeksByReading,
  NewWeek,
  SoloTextAndTools,
} from '@/types/sermons';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { BsChevronDown, BsPencilSquare } from 'react-icons/bs';
import { useNotifications } from '../providers/notification-provider';
import { useAppState } from '../providers/state-provider';
import Tools from '../sermons/tools';
import Button from './button';
import Dialog from './dialog';
import InlineSearchResults from './inline-search-results';
import LectionaryResources from './lectionary-resources';
import LectionaryToolsTabs from './lectionary-tools-tabs';
import { SuccessResponse } from './my-account';
import Select from './select';
import SizedHeading from './sized-heading';
import TextInput from './text-input';

export default function LectionaryTable({
  lectionaryWeeks: parentLectionaryWeeks,
  currentWeeks: parentCurrentWeeks,
}: {
  lectionaryWeeks: NewLectionaryWeeks;
  currentWeeks: NewLectionaryWeeksByReading;
}) {
  const {
    state: { user, token, isBrowser, isLoading, searchParams },
  } = useAppState();

  const addNotification = useNotifications();

  const qTab = searchParams.get('tab');
  const tabYear = ((qTab && qTab.slice(-1).toLowerCase()) || null) as
    | keyof LectionaryWeeks
    | null;
  const [tabTools, setTabTools] = useState<LECTIONARY_TOOLS_TAB>('Prep');
  const [page, setPage] = useState(1);

  const qLectionary = searchParams.get('lectionary');
  const lectionaryType: LECTIONARY_TYPES_TAB = isLectionaryType(qLectionary)
    ? qLectionary
    : 'Revised Common Lectionary';

  const [lectionaryWeeks, setLectionaryWeeks] = useState(parentLectionaryWeeks);
  const [currentWeeks, setCurrentWeeks] = useState(parentCurrentWeeks);

  const [lectionaryLoading, setLectionaryLoading] = useState(false);

  useEffect(() => {
    if (tabYear || qLectionary) {
      setLectionaryLoading(true);

      (async () => {
        const lectionaryWeeks = await fetchNewWeeks(
          token,
          LECTIONARY_TYPES_MAP[lectionaryType],
        );
        const currentWeeks = await fetchCurrentNewWeek(
          undefined,
          LECTIONARY_TYPES_MAP[lectionaryType],
          token,
        );
        if (!('a' in lectionaryWeeks)) {
          if (isError(lectionaryWeeks)) {
            addNotification({
              type: 'error',
              message: lectionaryWeeks.message,
            });
          }
          if (isError(currentWeeks)) {
            addNotification({ type: 'error', message: currentWeeks.message });
          }
          setLectionaryLoading(false);
          return;
        }
        if (isError(currentWeeks)) {
          addNotification({ type: 'error', message: currentWeeks.message });
          setLectionaryLoading(false);
          return;
        }
        setLectionaryWeeks(lectionaryWeeks);
        setCurrentWeeks(currentWeeks);

        setLectionaryLoading(false);
      })().catch(console.error);
    }
  }, [tabYear, qLectionary]);

  const firstCurrentWeek =
    currentWeeks[
      Object.keys(currentWeeks)[0] as keyof NewLectionaryWeeksByReading
    ];
  const currentCycle = (firstCurrentWeek.year?.toLowerCase() ||
    'a') as keyof LectionaryWeeks;
  const [selectedYear, setSelectedYear] = useState<keyof NewLectionaryWeeks>(
    tabYear ? tabYear : currentCycle,
  );
  const [selectedService, setSelectedService] = useState<string>();
  const [selectedReading, setSelectedReading] =
    useState<keyof NewLectionaryWeeksByReading>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAlternate, setSelectedAlternate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textAndTools, setTextAndTools] = useState<SoloTextAndTools>();

  const [dynamicLectionaryWeeks, setDynamicLectionaryWeeks] =
    useState(lectionaryWeeks);

  const lectionaryWeeksByServiceName = dynamicLectionaryWeeks[selectedYear]
    .filter((w) => w.priority === 'Primary')
    .reduce(
      (obj, week) => {
        if (week.serviceNameL) {
          if (!obj[week.serviceNameL]) {
            obj[week.serviceNameL] = {
              [week.reading]: week,
            } as Partial<NewLectionaryWeeksByReading>;
          } else {
            obj[week.serviceNameL]![week.reading] = week;
          }
        }
        return obj;
      },
      {} as Record<string, Partial<NewLectionaryWeeksByReading | undefined>>,
    );

  const lectionaryAlternatesByServiceName = dynamicLectionaryWeeks[selectedYear]
    .filter((w) => w.priority === 'Alternate')
    .reduce(
      (obj, week) => {
        if (week.serviceNameL) {
          if (!obj[week.serviceNameL]) {
            obj[week.serviceNameL] = {
              [week.reading]: week,
            } as Partial<NewLectionaryWeeksByReading>;
          } else {
            obj[week.serviceNameL]![week.reading] = week;
          }
        }
        return obj;
      },
      {} as Record<string, Partial<NewLectionaryWeeksByReading | undefined>>,
    );

  useEffect(() => {
    setSelectedYear(tabYear ? tabYear : currentCycle);
  }, [tabYear]);

  const selectedWeeks = selectedService
    ? selectedAlternate
      ? lectionaryAlternatesByServiceName[selectedService]
      : lectionaryWeeksByServiceName[selectedService]
    : undefined;
  const firstSelectedWeek =
    selectedWeeks?.[
      Object.keys(selectedWeeks || {})[0] as keyof NewLectionaryWeeksByReading
    ];
  const selectedWeek = selectedReading
    ? selectedAlternate
      ? selectedWeeks?.[selectedReading]
      : selectedWeeks?.[selectedReading]
    : undefined;
  const currentPericope = selectedWeek?.bibleCode
    ?.split(' or ')
    [selectedIndex].split(',')[0];

  useEffect(() => {
    if (!selectedService || !selectedReading || selectedIndex === undefined) {
      return;
    }
    setLoading(true);
    (async () => {
      const result = await fetchTextAndTools(currentPericope!, token);
      if (!('statusCode' in result)) {
        setTextAndTools(result);
        setLoading(false);
      }
    })().catch(console.error);
  }, [selectedService, selectedReading, selectedIndex, selectedAlternate]);

  const firstWeeks =
    lectionaryWeeksByServiceName[Object.keys(lectionaryWeeksByServiceName)[0]];
  const firstWeeksKeys = Object.keys(firstWeeks || {});

  let season: string | undefined = undefined;
  const [collapsedSeasons, setCollapsedSeasons] = useState(new Set<string>());

  const serviceNames = Object.keys(lectionaryWeeksByServiceName);

  const selectedAlternates = selectedService
    ? lectionaryAlternatesByServiceName[selectedService]
    : undefined;

  const totalColumns = firstWeeksKeys.length + 1;
  const firstPercent = ((100 / totalColumns) * 5) / 6;
  const otherPercent = (100 - firstPercent) / (totalColumns - 1);
  const otherPercents = Array(totalColumns - 1).fill(otherPercent);
  const gridTemplateColumns = `${firstPercent}% ${otherPercents.join('% ')}%`;

  const [isEditingWeek, setIsEditingWeek] = useState(false);

  const refreshValues = (lectionaryWeeks?: NewLectionaryWeeks) => {
    const aWeeks = [...(lectionaryWeeks || dynamicLectionaryWeeks).a];
    const bWeeks = [...(lectionaryWeeks || dynamicLectionaryWeeks).b];
    const cWeeks = [...(lectionaryWeeks || dynamicLectionaryWeeks).c];

    const weeksResponse = {
      a: aWeeks,
      b: bWeeks,
      c: cWeeks,
    };

    setDynamicLectionaryWeeks(weeksResponse);
  };

  useEffect(() => {
    refreshValues(lectionaryWeeks);
  }, []);

  const [saveLoading, setSaveLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const refreshPage = async () => {
    if (!token) {
      return;
    }
    setPageLoading(true);
    const lectionaryWeeks = await fetchNewWeeks(
      token,
      LECTIONARY_TYPES_MAP[lectionaryType],
    );
    if ('statusCode' in lectionaryWeeks) {
      addNotification({
        type: 'error',
        message:
          typeof lectionaryWeeks.message === 'string'
            ? lectionaryWeeks.message
            : lectionaryWeeks.message.join('\n'),
      });
    } else {
      lectionaryWeeks.a.sort((a, b) =>
        fromServerTime(a.actualDate).isAfter(b.actualDate)
          ? 1
          : fromServerTime(a.actualDate).isBefore(b.actualDate)
            ? -1
            : 0,
      );
      lectionaryWeeks.b.sort((a, b) =>
        fromServerTime(a.actualDate).isAfter(b.actualDate)
          ? 1
          : fromServerTime(a.actualDate).isBefore(b.actualDate)
            ? -1
            : 0,
      );
      lectionaryWeeks.c.sort((a, b) =>
        fromServerTime(a.actualDate).isAfter(b.actualDate)
          ? 1
          : fromServerTime(a.actualDate).isBefore(b.actualDate)
            ? -1
            : 0,
      );
      setDynamicLectionaryWeeks(lectionaryWeeks);
      setPageLoading(false);
    }
  };

  const setSelectedWeeksProp = (
    prop: keyof NewWeek,
    value: NewWeek[keyof NewWeek],
  ) => {
    if (!selectedWeeks) {
      return;
    }
    for (const key of Object.keys(
      selectedWeeks,
    ) as (keyof NewLectionaryWeeksByReading)[]) {
      const week = selectedWeeks[key];
      if (week) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        week[prop] = value as any;
      }
    }
  };

  const seasons = [...new Set(lectionaryWeeks.a.map((w) => w.season!))];

  const currentSeasonIndex = seasons.indexOf(firstCurrentWeek.season!);
  const preSeasons = seasons.slice(0, currentSeasonIndex);

  return (
    <div className='relative'>
      {(!isBrowser || isLoading || lectionaryLoading || pageLoading) && (
        <Loading className='absolute inset-0 z-10 bg-white' />
      )}
      {selectedService && selectedWeeks && firstSelectedWeek && (
        <Dialog
          isOpen={isEditingWeek}
          className='grid grid-cols-1 gap-4 rounded bg-white p-4'
          onBackdropClick={() => {
            setIsEditingWeek(false);
            setSelectedService(undefined);
            setSelectedReading(undefined);
            setSelectedIndex(0);
            setSelectedAlternate(false);
          }}
        >
          <form
            className='grid grid-cols-1 gap-4'
            onSubmit={async (e) => {
              e.preventDefault();

              setSaveLoading(true);

              for (const key of Object.keys(selectedWeeks)) {
                const week =
                  selectedWeeks[key as keyof NewLectionaryWeeksByReading]!;
                const res = await fetch(
                  `${process.env.NEXT_PUBLIC_API_BASE_URL}/lectionary/new-weeks/${week.id}`,
                  {
                    method: 'PATCH',
                    body: JSON.stringify(week),
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
                    cache: 'no-store',
                    next: {
                      tags: ['all', 'lectionary'],
                    },
                  },
                );

                const data: SuccessResponse | ErrorResponse = await res.json();

                if ('statusCode' in data) {
                  addNotification({
                    type: 'error',
                    message:
                      typeof data.message === 'string'
                        ? data.message
                        : data.message.join('\n'),
                  });
                } else {
                  addNotification({
                    type: 'success',
                    message: data.success,
                  });
                }
              }

              setSaveLoading(false);
            }}
          >
            <div className='grid grid-cols-12 gap-4'>
              <Select
                label='Lectionary'
                className='w-full'
                containerClassName='col-span-3'
                value={firstSelectedWeek.lectionary || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('lectionary', value);
                  refreshValues();
                }}
              >
                <option>RCL</option>
                <option>Catholic</option>
              </Select>
              <Select
                label='Year'
                className='w-full'
                value={firstSelectedWeek.year || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('year', value);
                  refreshValues();
                }}
              >
                <option>A</option>
                <option>B</option>
                <option>C</option>
              </Select>
              <Select
                label='Priority'
                className='w-full'
                containerClassName='col-span-2'
                value={firstSelectedWeek.priority || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('priority', value);
                  refreshValues();
                }}
              >
                <option>Primary</option>
                <option>Alternate</option>
              </Select>
              <TextInput
                containerClassName='col-span-3'
                className='w-full'
                id={`${firstSelectedWeek.season}`}
                label='Season'
                value={firstSelectedWeek.season || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('season', value);
                  refreshValues();
                }}
              />
              <TextInput
                containerClassName='col-span-3'
                className='w-full'
                id={`${firstSelectedWeek.serviceNameFull}`}
                label='Service Name Full'
                value={firstSelectedWeek.serviceNameFull || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('serviceNameFull', value);
                  refreshValues();
                }}
              />
              <TextInput
                containerClassName='col-span-4'
                className='w-full'
                id={`${firstSelectedWeek.serviceNameL}`}
                label='Service Name L'
                value={firstSelectedWeek.serviceNameL || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('serviceNameL', value);
                  setSelectedService(value);
                  refreshValues();
                }}
              />
              <TextInput
                containerClassName='col-span-4'
                className='w-full'
                id={`${firstSelectedWeek.serviceNameM}`}
                label='Service Name M'
                value={firstSelectedWeek.serviceNameM || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('serviceNameM', value);
                  refreshValues();
                }}
              />
              <TextInput
                containerClassName='col-span-4'
                className='w-full'
                id={`${firstSelectedWeek.serviceNameS}`}
                label='Service Name S'
                value={firstSelectedWeek.serviceNameS || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('serviceNameS', value);
                  refreshValues();
                }}
              />
              <TextInput
                containerClassName='col-span-4'
                className='w-full'
                id={`${firstSelectedWeek.weekDay}`}
                label='Week Day'
                value={firstSelectedWeek.weekDay || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('weekDay', value);
                  refreshValues();
                }}
              />
              <TextInput
                containerClassName='col-span-4'
                className='w-full'
                id={`${firstSelectedWeek.notes}`}
                label='Notes'
                value={firstSelectedWeek.notes || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('notes', value);
                  refreshValues();
                }}
              />
              <TextInput
                containerClassName='col-span-4'
                className='w-full'
                id={`${firstSelectedWeek.date}`}
                label='Date'
                value={firstSelectedWeek.date || undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedWeeksProp('date', value);
                  refreshValues();
                }}
              />
            </div>
            {Object.keys(selectedWeeks).map((reading) => {
              const week =
                selectedWeeks[reading as keyof NewLectionaryWeeksByReading]!;
              return (
                <React.Fragment key={reading}>
                  <hr />
                  <div className='grid grid-cols-12 gap-4'>
                    <TextInput
                      containerClassName='col-span-4'
                      className='w-full'
                      id={`${reading}-${week.season}-${week.id}`}
                      label='Citation L'
                      value={week.citationL || undefined}
                      onChange={(e) => {
                        const value = e.target.value;
                        week.citationL = value;
                        refreshValues();
                      }}
                    />
                    <TextInput
                      containerClassName='col-span-4'
                      className='w-full'
                      id={`${reading}-${week.season}-${week.id}`}
                      label='Citation M'
                      value={week.citationM || undefined}
                      onChange={(e) => {
                        const value = e.target.value;
                        week.citationM = value;
                        refreshValues();
                      }}
                    />
                    <TextInput
                      containerClassName='col-span-4'
                      className='w-full'
                      id={`${reading}-${week.season}-${week.id}`}
                      label='Citation S'
                      value={week.citationS || undefined}
                      onChange={(e) => {
                        const value = e.target.value;
                        week.citationS = value;
                        refreshValues();
                      }}
                    />
                    <TextInput
                      containerClassName='col-span-12'
                      className='w-full'
                      id={`${reading}-${week.season}-${week.id}`}
                      label='Bible Code'
                      value={week.bibleCode || undefined}
                      onChange={(e) => {
                        const value = e.target.value;
                        week.bibleCode = value;
                        refreshValues();
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
            <div className='flex justify-end'>
              <Button
                type='submit'
                loading={saveLoading}
                disabled={saveLoading}
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog>
      )}
      <div
        className={classNames(
          'min-w-[calc(768px_-_2rem_-_4px)] overflow-auto border-x border-black md:border-0',
          {
            'w-[calc(100%_+_3.75rem)]': user?.isAdmin,
          },
        )}
      >
        <div
          className={classNames('grid grid-cols-1', {
            'w-[calc(100%_-_3.75rem)]': user?.isAdmin,
          })}
        >
          <div
            className='grid text-center font-bold'
            style={{
              gridTemplateColumns,
            }}
          >
            <div className='relative min-h-11 border border-x-0 border-b-0 border-black p-2 md:!border-l'>
              <div className='absolute left-2 top-0 flex h-full cursor-pointer items-center text-xl'>
                <BsChevronDown
                  className={classNames('h-[20px] w-[20px] transition-all', {
                    'rotate-180': !preSeasons.every((s) =>
                      collapsedSeasons.has(s),
                    ),
                  })}
                  onClick={() => {
                    const newCollapsedSeasons = new Set([...collapsedSeasons]);
                    if (preSeasons.every((s) => collapsedSeasons.has(s))) {
                      newCollapsedSeasons.clear();
                    } else {
                      for (const season of preSeasons) {
                        newCollapsedSeasons.add(season);
                      }
                    }
                    setCollapsedSeasons(newCollapsedSeasons);
                    setSelectedService(undefined);
                    setSelectedReading(undefined);
                    setSelectedIndex(0);
                    setSelectedAlternate(false);
                  }}
                />
              </div>
              Year {selectedYear.toUpperCase()}
            </div>
            {firstWeeksKeys.map((key, index) => (
              <div
                key={`heading-${key}`}
                className={classNames(
                  'min-h-11 border border-b-0 border-r-0 border-black p-2',
                  {
                    'md:!border-r': index === firstWeeksKeys.length - 1,
                  },
                )}
              >
                {key}
              </div>
            ))}
          </div>
          {serviceNames.map((serviceName, serviceIndex) => {
            const firstWeek =
              lectionaryWeeksByServiceName[serviceName]![
                Object.keys(
                  lectionaryWeeksByServiceName[serviceName] || {},
                )[0] as keyof NewLectionaryWeeksByReading
              ]!;
            const firstAlternate =
              selectedAlternates?.[
                Object.keys(
                  selectedAlternates || {},
                )[0] as keyof NewLectionaryWeeksByReading
              ];

            const date = firstWeek.actualDate
              ? fromServerTime(firstWeek.actualDate)
              : undefined;

            const isStartOfSeason = season !== firstWeek.season;
            season = firstWeek.season || undefined;

            const lastWeeks =
              lectionaryWeeksByServiceName[serviceNames.slice(-1)[0]];
            const lastWeek =
              lastWeeks?.[
                Object.keys(
                  lastWeeks || {},
                ).pop() as keyof NewLectionaryWeeksByReading
              ];

            return (
              <React.Fragment key={serviceName}>
                {(!collapsedSeasons.has(firstWeek.season!) ||
                  isStartOfSeason) && (
                  <div
                    className={classNames('relative grid min-h-11 align-top', {
                      'bg-sky-50': isStartOfSeason,
                    })}
                    style={{
                      gridTemplateColumns,
                    }}
                  >
                    <div
                      className={classNames(
                        'relative flex h-full items-center justify-between border border-l-0 border-r-0 border-black p-2 md:!border-l',
                        {
                          'bg-neutral-200':
                            serviceName === selectedService &&
                            !selectedAlternate,
                          'font-bold text-sermons-dark':
                            currentCycle === selectedYear &&
                            firstWeek.id === firstCurrentWeek.id &&
                            serviceName !== selectedService,
                          'font-bold text-black':
                            currentCycle === selectedYear &&
                            firstWeek.id === firstCurrentWeek.id &&
                            serviceName === selectedService,
                          'text-right': !collapsedSeasons.has(
                            firstWeek.season!,
                          ),
                          '!border-r': collapsedSeasons.has(firstWeek.season!),
                          'border-b-0':
                            (serviceIndex !== serviceNames.length - 1 &&
                              !collapsedSeasons.has(firstWeek.season!)) ||
                            (isStartOfSeason &&
                              lastWeek?.season !== firstWeek.season &&
                              collapsedSeasons.has(firstWeek.season!)),
                        },
                      )}
                      style={{
                        gridColumn: collapsedSeasons.has(firstWeek.season!)
                          ? `span ${totalColumns} / span ${totalColumns}`
                          : `span 1 / span 1`,
                      }}
                    >
                      {isStartOfSeason &&
                        !(
                          currentCycle === selectedYear &&
                          firstWeek.serviceNameL ===
                            firstCurrentWeek.serviceNameL
                        ) && (
                          <div className='cursor-pointer text-xl'>
                            <BsChevronDown
                              className={classNames(
                                'h-[20px] w-[20px] transition-all',
                                {
                                  'rotate-180': !collapsedSeasons.has(
                                    firstWeek.season!,
                                  ),
                                },
                              )}
                              onClick={() => {
                                const newCollapsedSeasons = new Set([
                                  ...collapsedSeasons,
                                ]);
                                if (
                                  newCollapsedSeasons.has(firstWeek.season!)
                                ) {
                                  newCollapsedSeasons.delete(firstWeek.season!);
                                } else {
                                  newCollapsedSeasons.add(firstWeek.season!);
                                }
                                setCollapsedSeasons(newCollapsedSeasons);
                                setSelectedService(undefined);
                                setSelectedReading(undefined);
                                setSelectedIndex(0);
                                setSelectedAlternate(false);
                              }}
                            />
                          </div>
                        )}
                      {!isStartOfSeason &&
                        !(
                          currentCycle === selectedYear &&
                          firstWeek.serviceNameL ===
                            firstCurrentWeek.serviceNameL
                        ) && <div />}
                      {isStartOfSeason &&
                        currentCycle === selectedYear &&
                        firstWeek.serviceNameL ===
                          firstCurrentWeek.serviceNameL && (
                          <Image
                            className='cursor-pointer'
                            src='/img/lectionary.com-icon.svg'
                            width={24}
                            height={24}
                            alt=''
                            onClick={() => {
                              const newCollapsedSeasons = new Set([
                                ...collapsedSeasons,
                              ]);
                              if (newCollapsedSeasons.has(firstWeek.season!)) {
                                newCollapsedSeasons.delete(firstWeek.season!);
                              } else {
                                newCollapsedSeasons.add(firstWeek.season!);
                              }
                              setCollapsedSeasons(newCollapsedSeasons);
                              setSelectedService(undefined);
                              setSelectedReading(undefined);
                              setSelectedIndex(0);
                              setSelectedAlternate(false);
                            }}
                          />
                        )}
                      {!isStartOfSeason &&
                        currentCycle === selectedYear &&
                        firstWeek.serviceNameL ===
                          firstCurrentWeek.serviceNameL && (
                          <Image
                            src='/img/lectionary.com-icon.svg'
                            width={24}
                            height={24}
                            alt=''
                          />
                        )}
                      {collapsedSeasons.has(firstWeek.season!) && (
                        <>
                          <div>{firstWeek.season}</div>
                          <div className='size-[20px]' />
                        </>
                      )}
                      {!collapsedSeasons.has(firstWeek.season!) && (
                        <SizedHeading
                          className={classNames({
                            '!max-w-[calc(100%_-_28px)]':
                              isStartOfSeason &&
                              !(
                                currentCycle === selectedYear &&
                                firstWeek.serviceNameL ===
                                  firstCurrentWeek.serviceNameL
                              ),
                            '!max-w-full':
                              !isStartOfSeason &&
                              !(
                                currentCycle === selectedYear &&
                                firstWeek.serviceNameL ===
                                  firstCurrentWeek.serviceNameL
                              ),
                            '!max-w-[calc(100%_-_40px)]':
                              currentCycle === selectedYear &&
                              firstWeek.serviceNameL ===
                                firstCurrentWeek.serviceNameL,
                          })}
                          headings={[
                            <React.Fragment key={firstWeek.serviceNameL}>
                              <Link
                                className={classNames('down hover:underline', {
                                  'text-sermons-dark':
                                    currentCycle !== selectedYear ||
                                    firstWeek.id !== firstCurrentWeek.id,
                                })}
                                href={`/search/lectionary?year=${
                                  firstWeek.year
                                }&week=${encodeURIComponent(
                                  firstWeek.serviceNameL || '',
                                )}&type=Sermon%20Prep`}
                                aria-label={
                                  firstWeek.serviceNameFull ||
                                  firstWeek.serviceNameL ||
                                  ''
                                }
                              >
                                {firstWeek.serviceNameL}
                              </Link>
                              <span className='align-top font-mono font-normal text-black'>
                                {' '}
                                - {date ? date.format('MM/DD') : 'N / A'}
                              </span>
                            </React.Fragment>,
                            <React.Fragment key={firstWeek.serviceNameM}>
                              <Link
                                className={classNames('down hover:underline', {
                                  'text-sermons-dark':
                                    currentCycle !== selectedYear ||
                                    firstWeek.id !== firstCurrentWeek.id,
                                })}
                                href={`/search/lectionary?year=${
                                  firstWeek.year
                                }&week=${encodeURIComponent(
                                  firstWeek.serviceNameL || '',
                                )}&type=Sermon%20Prep`}
                                aria-label={
                                  firstWeek.serviceNameFull ||
                                  firstWeek.serviceNameL ||
                                  ''
                                }
                              >
                                {firstWeek.serviceNameM}
                              </Link>
                              <span className='align-top font-mono font-normal text-black'>
                                {' '}
                                - {date ? date.format('MM/DD') : 'N / A'}
                              </span>
                            </React.Fragment>,
                            <React.Fragment key={firstWeek.serviceNameS}>
                              <Link
                                className={classNames('down hover:underline', {
                                  'text-sermons-dark':
                                    currentCycle !== selectedYear ||
                                    firstWeek.id !== firstCurrentWeek.id,
                                })}
                                href={`/search/lectionary?year=${
                                  firstWeek.year
                                }&week=${encodeURIComponent(
                                  firstWeek.serviceNameL || '',
                                )}&type=Sermon%20Prep`}
                                aria-label={
                                  firstWeek.serviceNameFull ||
                                  firstWeek.serviceNameL ||
                                  ''
                                }
                              >
                                {firstWeek.serviceNameS}
                              </Link>
                              <span className='align-top font-mono font-normal text-black'>
                                {' '}
                                - {date ? date.format('MM/DD') : 'N / A'}
                              </span>
                            </React.Fragment>,
                          ]}
                        />
                      )}
                    </div>
                    {!collapsedSeasons.has(firstWeek.season!) &&
                      firstWeeksKeys.map((key, index) => {
                        const week =
                          lectionaryWeeksByServiceName[serviceName]![
                            key as keyof NewLectionaryWeeksByReading
                          ]!;
                        return (
                          <div
                            key={key}
                            className={classNames(
                              'flex h-full items-center justify-start border border-r-0 border-black p-2',
                              {
                                'bg-neutral-200': selectedWeek?.id === week.id,
                                'md:!border-r':
                                  index === firstWeeksKeys.length - 1,
                                'border-b-0':
                                  serviceIndex !== serviceNames.length - 1,
                              },
                            )}
                          >
                            {week.citationL !== null && (
                              <SizedHeading
                                headings={[
                                  <span key={week.citationL}>
                                    {week.citationL
                                      .split(' or ')
                                      .map((citation, index) => (
                                        <React.Fragment key={citation}>
                                          {index > 0 && (
                                            <span className='font-bold'>
                                              {' '}
                                              or{' '}
                                            </span>
                                          )}
                                          <span
                                            className={classNames(
                                              'cursor-pointer hover:underline',
                                              {
                                                'font-bold text-sermons-dark':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id !== selectedWeek?.id,
                                                'font-bold text-black':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id === selectedWeek?.id,
                                                'text-sermons-dark':
                                                  currentCycle !==
                                                    selectedYear ||
                                                  firstWeek.id !==
                                                    firstCurrentWeek.id,
                                              },
                                            )}
                                            onClick={() => {
                                              if (
                                                selectedWeek?.id === week.id &&
                                                selectedIndex === index
                                              ) {
                                                setSelectedService(undefined);
                                                setSelectedReading(undefined);
                                                setSelectedIndex(0);
                                                setSelectedAlternate(false);
                                              } else {
                                                setSelectedService(
                                                  week.serviceNameL || '',
                                                );
                                                setSelectedReading(
                                                  week.reading,
                                                );
                                                setSelectedIndex(index);
                                                setSelectedAlternate(false);
                                              }
                                            }}
                                          >
                                            {citation}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                  </span>,
                                  <span key={week.citationM}>
                                    {week.citationM
                                      ?.split(' or ')
                                      .map((citation, index) => (
                                        <React.Fragment key={citation}>
                                          {index > 0 && (
                                            <span className='font-bold'>
                                              {' '}
                                              or{' '}
                                            </span>
                                          )}
                                          <span
                                            className={classNames(
                                              'cursor-pointer hover:underline',
                                              {
                                                'font-bold text-sermons-dark':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id !== selectedWeek?.id,
                                                'font-bold text-black':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id === selectedWeek?.id,
                                                'text-sermons-dark':
                                                  currentCycle !==
                                                    selectedYear ||
                                                  firstWeek.id !==
                                                    firstCurrentWeek.id,
                                              },
                                            )}
                                            onClick={() => {
                                              if (
                                                selectedWeek?.id === week.id &&
                                                selectedIndex === index
                                              ) {
                                                setSelectedService(undefined);
                                                setSelectedReading(undefined);
                                                setSelectedIndex(0);
                                                setSelectedAlternate(false);
                                              } else {
                                                setSelectedService(
                                                  week.serviceNameL || '',
                                                );
                                                setSelectedReading(
                                                  week.reading,
                                                );
                                                setSelectedIndex(index);
                                                setSelectedAlternate(false);
                                              }
                                            }}
                                          >
                                            {citation}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                  </span>,
                                  <span key={week.citationS}>
                                    {week.citationS
                                      ?.split(' or ')
                                      .map((citation, index) => (
                                        <React.Fragment key={citation}>
                                          {index > 0 && (
                                            <span className='font-bold'>
                                              {' '}
                                              or{' '}
                                            </span>
                                          )}
                                          <span
                                            className={classNames(
                                              'cursor-pointer hover:underline',
                                              {
                                                'font-bold text-sermons-dark':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id !== selectedWeek?.id,
                                                'font-bold text-black':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id === selectedWeek?.id,
                                                'text-sermons-dark':
                                                  currentCycle !==
                                                    selectedYear ||
                                                  firstWeek.id !==
                                                    firstCurrentWeek.id,
                                              },
                                            )}
                                            onClick={() => {
                                              if (
                                                selectedWeek?.id === week.id &&
                                                selectedIndex === index
                                              ) {
                                                setSelectedService(undefined);
                                                setSelectedReading(undefined);
                                                setSelectedIndex(0);
                                                setSelectedAlternate(false);
                                              } else {
                                                setSelectedService(
                                                  week.serviceNameL || '',
                                                );
                                                setSelectedReading(
                                                  week.reading,
                                                );
                                                setSelectedIndex(index);
                                                setSelectedAlternate(false);
                                              }
                                            }}
                                          >
                                            {citation}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                  </span>,
                                  <span key={`${week.citationL}-stacked`}>
                                    {week.citationL
                                      ?.split(' or ')
                                      .map((citation, index) => (
                                        <React.Fragment key={citation}>
                                          {index > 0 && <br />}
                                          <span
                                            className={classNames(
                                              'cursor-pointer hover:underline',
                                              {
                                                'font-bold text-sermons-dark':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id !== selectedWeek?.id,
                                                'font-bold text-black':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id === selectedWeek?.id,
                                                'text-sermons-dark':
                                                  currentCycle !==
                                                    selectedYear ||
                                                  firstWeek.id !==
                                                    firstCurrentWeek.id,
                                              },
                                            )}
                                            onClick={() => {
                                              if (
                                                selectedWeek?.id === week.id &&
                                                selectedIndex === index
                                              ) {
                                                setSelectedService(undefined);
                                                setSelectedReading(undefined);
                                                setSelectedIndex(0);
                                                setSelectedAlternate(false);
                                              } else {
                                                setSelectedService(
                                                  week.serviceNameL || '',
                                                );
                                                setSelectedReading(
                                                  week.reading,
                                                );
                                                setSelectedIndex(index);
                                                setSelectedAlternate(false);
                                              }
                                            }}
                                          >
                                            {citation}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                  </span>,
                                  <span key={`${week.citationM}-stacked`}>
                                    {week.citationM
                                      ?.split(' or ')
                                      .map((citation, index) => (
                                        <React.Fragment key={citation}>
                                          {index > 0 && <br />}
                                          <span
                                            className={classNames(
                                              'cursor-pointer hover:underline',
                                              {
                                                'font-bold text-sermons-dark':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id !== selectedWeek?.id,
                                                'font-bold text-black':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id === selectedWeek?.id,
                                                'text-sermons-dark':
                                                  currentCycle !==
                                                    selectedYear ||
                                                  firstWeek.id !==
                                                    firstCurrentWeek.id,
                                              },
                                            )}
                                            onClick={() => {
                                              if (
                                                selectedWeek?.id === week.id &&
                                                selectedIndex === index
                                              ) {
                                                setSelectedService(undefined);
                                                setSelectedReading(undefined);
                                                setSelectedIndex(0);
                                                setSelectedAlternate(false);
                                              } else {
                                                setSelectedService(
                                                  week.serviceNameL || '',
                                                );
                                                setSelectedReading(
                                                  week.reading,
                                                );
                                                setSelectedIndex(index);
                                                setSelectedAlternate(false);
                                              }
                                            }}
                                          >
                                            {citation}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                  </span>,
                                  <span key={`${week.citationS}-stacked`}>
                                    {week.citationS
                                      ?.split(' or ')
                                      .map((citation, index) => (
                                        <React.Fragment key={citation}>
                                          {index > 0 && <br />}
                                          <span
                                            className={classNames(
                                              'cursor-pointer hover:underline',
                                              {
                                                'font-bold text-sermons-dark':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id !== selectedWeek?.id,
                                                'font-bold text-black':
                                                  currentCycle ===
                                                    selectedYear &&
                                                  firstWeek.id ===
                                                    firstCurrentWeek.id &&
                                                  week.id === selectedWeek?.id,
                                                'text-sermons-dark':
                                                  currentCycle !==
                                                    selectedYear ||
                                                  firstWeek.id !==
                                                    firstCurrentWeek.id,
                                              },
                                            )}
                                            onClick={() => {
                                              if (
                                                selectedWeek?.id === week.id &&
                                                selectedIndex === index
                                              ) {
                                                setSelectedService(undefined);
                                                setSelectedReading(undefined);
                                                setSelectedIndex(0);
                                                setSelectedAlternate(false);
                                              } else {
                                                setSelectedService(
                                                  week.serviceNameL || '',
                                                );
                                                setSelectedReading(
                                                  week.reading,
                                                );
                                                setSelectedIndex(index);
                                                setSelectedAlternate(false);
                                              }
                                            }}
                                          >
                                            {citation}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                  </span>,
                                ]}
                              />
                            )}
                            {week.citationL === null && 'N/A'}
                          </div>
                        );
                      })}
                    {user?.isAdmin &&
                      !collapsedSeasons.has(firstWeek.season!) && (
                        <div
                          key={serviceName}
                          className='absolute left-full flex h-full items-center justify-center'
                        >
                          <Button
                            className='float-right ml-2 inline-flex size-10 items-center justify-center text-3xl'
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEditingWeek(true);
                              setSelectedService(serviceName);
                              setSelectedReading(undefined);
                              setSelectedIndex(0);
                              setSelectedAlternate(false);
                            }}
                          >
                            <BsPencilSquare />
                          </Button>
                        </div>
                      )}
                  </div>
                )}
                {selectedAlternates &&
                  firstAlternate &&
                  serviceName === firstAlternate.serviceNameL && (
                    <div
                      className={classNames('relative grid align-top', {
                        'bg-sky-50': isStartOfSeason,
                      })}
                      style={{
                        gridTemplateColumns,
                      }}
                    >
                      <div
                        className={classNames(
                          'relative flex h-full items-center justify-between border border-l-0 border-r-0 border-black p-2 text-right md:!border-l',
                          {
                            'bg-neutral-200':
                              serviceName === selectedService &&
                              selectedAlternate,
                            'border-b-0':
                              serviceIndex !== serviceNames.length - 1,
                          },
                        )}
                        style={{
                          gridColumn: collapsedSeasons.has(
                            firstAlternate.season!,
                          )
                            ? `span ${totalColumns} / span ${totalColumns}`
                            : `span 1 / span 1`,
                        }}
                      >
                        <div />
                        <SizedHeading
                          className={classNames({
                            '!max-w-[calc(100%_-_20px)]':
                              isStartOfSeason &&
                              !(
                                currentCycle === selectedYear &&
                                firstAlternate.id === firstCurrentWeek.id
                              ),
                            '!max-w-[calc(100%_-_20px_-_32px)]':
                              isStartOfSeason &&
                              currentCycle === selectedYear &&
                              firstAlternate.id === firstCurrentWeek.id,
                            '!max-w-[calc(100%_-_32px)]':
                              !isStartOfSeason &&
                              currentCycle === selectedYear &&
                              firstAlternate.id === firstCurrentWeek.id,
                          })}
                          headings={[
                            <React.Fragment key={firstAlternate.serviceNameL}>
                              Alternate
                            </React.Fragment>,
                          ]}
                        />
                      </div>
                      {!collapsedSeasons.has(firstAlternate.season!) &&
                        firstWeeksKeys.map((key, index) => {
                          const week =
                            selectedAlternates[
                              key as keyof NewLectionaryWeeksByReading
                            ]!;
                          return (
                            <div
                              key={key}
                              className={classNames(
                                'flex h-full items-center justify-start border border-r-0 border-black p-2',
                                {
                                  'bg-neutral-200':
                                    selectedWeek?.id === week.id,
                                  'md:!border-r':
                                    index === firstWeeksKeys.length - 1,
                                  'border-b-0':
                                    serviceIndex !== serviceNames.length - 1,
                                },
                              )}
                            >
                              {week.citationL !== null && (
                                <SizedHeading
                                  headings={[
                                    <span key={week.citationL}>
                                      {week.citationL
                                        .split(' or ')
                                        .map((citation, index) => (
                                          <React.Fragment key={citation}>
                                            {index > 0 && (
                                              <span className='font-bold'>
                                                {' '}
                                                or{' '}
                                              </span>
                                            )}
                                            <span
                                              className={classNames(
                                                'cursor-pointer hover:underline',
                                                {
                                                  'font-bold text-sermons-dark':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id !==
                                                      selectedWeek?.id,
                                                  'font-bold text-black':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id ===
                                                      selectedWeek?.id,
                                                  'text-sermons-dark':
                                                    currentCycle !==
                                                      selectedYear ||
                                                    firstAlternate.id !==
                                                      firstCurrentWeek.id,
                                                },
                                              )}
                                              onClick={() => {
                                                if (
                                                  selectedWeek?.id ===
                                                    week.id &&
                                                  selectedIndex === index
                                                ) {
                                                  setSelectedService(undefined);
                                                  setSelectedReading(undefined);
                                                  setSelectedIndex(0);
                                                  setSelectedAlternate(false);
                                                } else {
                                                  setSelectedService(
                                                    week.serviceNameL || '',
                                                  );
                                                  setSelectedReading(
                                                    week.reading,
                                                  );
                                                  setSelectedIndex(index);
                                                  setSelectedAlternate(true);
                                                }
                                              }}
                                            >
                                              {citation}
                                            </span>
                                          </React.Fragment>
                                        ))}
                                    </span>,
                                    <span key={week.citationM}>
                                      {week.citationM
                                        ?.split(' or ')
                                        .map((citation, index) => (
                                          <React.Fragment key={citation}>
                                            {index > 0 && (
                                              <span className='font-bold'>
                                                {' '}
                                                or{' '}
                                              </span>
                                            )}
                                            <span
                                              className={classNames(
                                                'cursor-pointer hover:underline',
                                                {
                                                  'font-bold text-sermons-dark':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id !==
                                                      selectedWeek?.id,
                                                  'font-bold text-black':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id ===
                                                      selectedWeek?.id,
                                                  'text-sermons-dark':
                                                    currentCycle !==
                                                      selectedYear ||
                                                    firstAlternate.id !==
                                                      firstCurrentWeek.id,
                                                },
                                              )}
                                              onClick={() => {
                                                if (
                                                  selectedWeek?.id ===
                                                    week.id &&
                                                  selectedIndex === index
                                                ) {
                                                  setSelectedService(undefined);
                                                  setSelectedReading(undefined);
                                                  setSelectedIndex(0);
                                                  setSelectedAlternate(false);
                                                } else {
                                                  setSelectedService(
                                                    week.serviceNameL || '',
                                                  );
                                                  setSelectedReading(
                                                    week.reading,
                                                  );
                                                  setSelectedIndex(index);
                                                  setSelectedAlternate(true);
                                                }
                                              }}
                                            >
                                              {citation}
                                            </span>
                                          </React.Fragment>
                                        ))}
                                    </span>,
                                    <span key={week.citationS}>
                                      {week.citationS
                                        ?.split(' or ')
                                        .map((citation, index) => (
                                          <React.Fragment key={citation}>
                                            {index > 0 && (
                                              <span className='font-bold'>
                                                {' '}
                                                or{' '}
                                              </span>
                                            )}
                                            <span
                                              className={classNames(
                                                'cursor-pointer hover:underline',
                                                {
                                                  'font-bold text-sermons-dark':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id !==
                                                      selectedWeek?.id,
                                                  'font-bold text-black':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id ===
                                                      selectedWeek?.id,
                                                  'text-sermons-dark':
                                                    currentCycle !==
                                                      selectedYear ||
                                                    firstAlternate.id !==
                                                      firstCurrentWeek.id,
                                                },
                                              )}
                                              onClick={() => {
                                                if (
                                                  selectedWeek?.id ===
                                                    week.id &&
                                                  selectedIndex === index
                                                ) {
                                                  setSelectedService(undefined);
                                                  setSelectedReading(undefined);
                                                  setSelectedIndex(0);
                                                  setSelectedAlternate(false);
                                                } else {
                                                  setSelectedService(
                                                    week.serviceNameL || '',
                                                  );
                                                  setSelectedReading(
                                                    week.reading,
                                                  );
                                                  setSelectedIndex(index);
                                                  setSelectedAlternate(true);
                                                }
                                              }}
                                            >
                                              {citation}
                                            </span>
                                          </React.Fragment>
                                        ))}
                                    </span>,
                                    <span key={`${week.citationL}-stacked`}>
                                      {week.citationL
                                        ?.split(' or ')
                                        .map((citation, index) => (
                                          <React.Fragment key={citation}>
                                            {index > 0 && <br />}
                                            <span
                                              className={classNames(
                                                'cursor-pointer hover:underline',
                                                {
                                                  'font-bold text-sermons-dark':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id !==
                                                      selectedWeek?.id,
                                                  'font-bold text-black':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id ===
                                                      selectedWeek?.id,
                                                  'text-sermons-dark':
                                                    currentCycle !==
                                                      selectedYear ||
                                                    firstAlternate.id !==
                                                      firstCurrentWeek.id,
                                                },
                                              )}
                                              onClick={() => {
                                                if (
                                                  selectedWeek?.id ===
                                                    week.id &&
                                                  selectedIndex === index
                                                ) {
                                                  setSelectedService(undefined);
                                                  setSelectedReading(undefined);
                                                  setSelectedIndex(0);
                                                  setSelectedAlternate(false);
                                                } else {
                                                  setSelectedService(
                                                    week.serviceNameL || '',
                                                  );
                                                  setSelectedReading(
                                                    week.reading,
                                                  );
                                                  setSelectedIndex(index);
                                                  setSelectedAlternate(true);
                                                }
                                              }}
                                            >
                                              {citation}
                                            </span>
                                          </React.Fragment>
                                        ))}
                                    </span>,
                                    <span key={`${week.citationM}-stacked`}>
                                      {week.citationM
                                        ?.split(' or ')
                                        .map((citation, index) => (
                                          <React.Fragment key={citation}>
                                            {index > 0 && <br />}
                                            <span
                                              className={classNames(
                                                'cursor-pointer hover:underline',
                                                {
                                                  'font-bold text-sermons-dark':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id !==
                                                      selectedWeek?.id,
                                                  'font-bold text-black':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id ===
                                                      selectedWeek?.id,
                                                  'text-sermons-dark':
                                                    currentCycle !==
                                                      selectedYear ||
                                                    firstAlternate.id !==
                                                      firstCurrentWeek.id,
                                                },
                                              )}
                                              onClick={() => {
                                                if (
                                                  selectedWeek?.id ===
                                                    week.id &&
                                                  selectedIndex === index
                                                ) {
                                                  setSelectedService(undefined);
                                                  setSelectedReading(undefined);
                                                  setSelectedIndex(0);
                                                  setSelectedAlternate(false);
                                                } else {
                                                  setSelectedService(
                                                    week.serviceNameL || '',
                                                  );
                                                  setSelectedReading(
                                                    week.reading,
                                                  );
                                                  setSelectedIndex(index);
                                                  setSelectedAlternate(true);
                                                }
                                              }}
                                            >
                                              {citation}
                                            </span>
                                          </React.Fragment>
                                        ))}
                                    </span>,
                                    <span key={`${week.citationS}-stacked`}>
                                      {week.citationS
                                        ?.split(' or ')
                                        .map((citation, index) => (
                                          <React.Fragment key={citation}>
                                            {index > 0 && <br />}
                                            <span
                                              className={classNames(
                                                'cursor-pointer hover:underline',
                                                {
                                                  'font-bold text-sermons-dark':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id !==
                                                      selectedWeek?.id,
                                                  'font-bold text-black':
                                                    currentCycle ===
                                                      selectedYear &&
                                                    firstAlternate.id ===
                                                      firstCurrentWeek.id &&
                                                    week.id ===
                                                      selectedWeek?.id,
                                                  'text-sermons-dark':
                                                    currentCycle !==
                                                      selectedYear ||
                                                    firstAlternate.id !==
                                                      firstCurrentWeek.id,
                                                },
                                              )}
                                              onClick={() => {
                                                if (
                                                  selectedWeek?.id ===
                                                    week.id &&
                                                  selectedIndex === index
                                                ) {
                                                  setSelectedService(undefined);
                                                  setSelectedReading(undefined);
                                                  setSelectedIndex(0);
                                                  setSelectedAlternate(false);
                                                } else {
                                                  setSelectedService(
                                                    week.serviceNameL || '',
                                                  );
                                                  setSelectedReading(
                                                    week.reading,
                                                  );
                                                  setSelectedIndex(index);
                                                  setSelectedAlternate(true);
                                                }
                                              }}
                                            >
                                              {citation}
                                            </span>
                                          </React.Fragment>
                                        ))}
                                    </span>,
                                  ]}
                                />
                              )}
                              {week.citationL === null && 'N/A'}
                            </div>
                          );
                        })}
                      {user?.isAdmin &&
                        !collapsedSeasons.has(firstAlternate.season!) && (
                          <div
                            key={serviceName}
                            className='absolute left-full flex h-full items-center justify-center'
                          >
                            <Button
                              className='float-right ml-2 inline-flex size-10 items-center justify-center text-3xl'
                              onClick={(e) => {
                                e.preventDefault();
                                setIsEditingWeek(true);
                                setSelectedService(serviceName);
                                setSelectedReading(undefined);
                                setSelectedIndex(0);
                                setSelectedAlternate(true);
                              }}
                            >
                              <BsPencilSquare />
                            </Button>
                          </div>
                        )}
                    </div>
                  )}
                {selectedWeek?.serviceNameL &&
                  [serviceName].includes(selectedWeek.serviceNameL) &&
                  selectedReading &&
                  !collapsedSeasons.has(firstWeek.season!) && (
                    <div className='grid grid-cols-1'>
                      {loading && (
                        <div
                          className={classNames(
                            'border border-t-0 border-black p-0 text-center',
                            {
                              'border-b-0':
                                serviceNames[serviceNames.length - 1] !==
                                selectedWeek?.serviceNameL,
                            },
                          )}
                        >
                          <div className='max-w-[calc(100vw_-_2rem_-_1px)] border-r border-black md:border-0'>
                            <LectionaryToolsTabs tab={tabTools} />
                            <Loading />
                          </div>
                        </div>
                      )}
                      {textAndTools && !loading && (
                        <div
                          className={classNames(
                            'border border-t-0 border-black p-0',
                            {
                              'border-b-0':
                                serviceNames[serviceNames.length - 1] !==
                                selectedWeek?.serviceNameL,
                            },
                          )}
                        >
                          <div className='max-w-[calc(100vw_-_2rem_-_1px)] border-r border-black md:border-0'>
                            <LectionaryToolsTabs
                              tab={tabTools}
                              setTab={(tab) => {
                                setPage(1);
                                setTabTools(tab);
                              }}
                            />
                            {tabTools === 'Prep' && (
                              <Tools
                                studyTools={textAndTools.studyTools}
                                week={selectedWeek}
                              />
                            )}
                            {tabTools === 'Bulletin Aids' &&
                              currentPericope && (
                                <InlineSearchResults
                                  pericope={currentPericope}
                                  type='Bulletin Aids'
                                  tab='Gospel'
                                  page={page}
                                />
                              )}
                            {tabTools === 'Sermon Aids' && currentPericope && (
                              <InlineSearchResults
                                pericope={currentPericope}
                                type='Sermon Aids'
                                tab='Gospel'
                                page={page}
                              />
                            )}
                            {tabTools === 'Illustrations' &&
                              currentPericope && (
                                <InlineSearchResults
                                  pericope={currentPericope}
                                  type='Illustrations'
                                  tab='Gospel'
                                  page={page}
                                />
                              )}
                            {tabTools === 'Resources' && firstSelectedWeek && (
                              <LectionaryResources
                                week={firstSelectedWeek}
                                onSave={() =>
                                  refreshPage().catch(console.error)
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
